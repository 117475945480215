import React from 'react';
import {
    FaFacebook,
    FaGoogle,
    FaApple,
    FaAmazon,
    FaInfoCircle
} from "react-icons/fa";

const Register = () => {
  return (
      <div className='register-container'>
          <h1 className='register-sign-up-text'>Sign up</h1>
          <span className='sign-in-with-text'>Sign up with</span>
                <div className='sign-in-with-social'>
                    <span><FaFacebook /></span>
                    <span><FaGoogle /></span>
                    <span><FaApple /></span>
                    <span><FaAmazon /></span>
                </div>
        <div className='contine-with-option'>
                        <span className='continue-with-option-or'>OR</span>
                </div>
        <div className='registration-box'>
            <div className='login-container-sub-boxes'>
                  <span className='input-field-title'>Email Address <span className='required-section'>*</span></span>
                  <div className='input-two-columns'>
                      <input className='lg-input lg-email-input' type='email' placeholder='Email account (case sensitive)' />
                      <button className='email-verification-btn'>Send</button>
                  </div>
                  <span className='input-captions'><FaInfoCircle /> Your email address is the account identifier and cannot be changed once registered. Please note it is case sensitive.</span>
                  <span className='input-field-title'>Verification Code: <span className='required-section'>*</span></span>
                  <div className='input-two-columns'>
                  <input className='lg-input lg-email-input' type='text' placeholder='Enter Verification Code' />
                  <button className='email-verification-btn'>Verify</button>
                  </div>
                  <hr />
                  <span className='input-field-title'>Password <span className='required-section'>*</span></span>
                                <input className='lg-input lg-email-input' type='password' placeholder='Enter Password' />
                  <span className='input-field-title'>Confirm Password <span className='required-section'>*</span></span>
                  <input className='lg-input lg-email-input' type='password' placeholder='Enter Password' />
                  <hr/>
                  <span className='input-field-title'>First Name <span className='required-section'>*</span></span>
                                <input className='lg-input lg-email-input' type='text' placeholder='Enter First Name' />
                  <span className='input-field-title'>Last Name <span className='required-section'>*</span></span>
                                <input className='lg-input lg-email-input' type='text' placeholder='Enter Last Name' />
                  <span className='input-field-title'>Mobile Number <span className='required-section'>*</span></span>
                                <input className='lg-input lg-email-input' type='text' placeholder='Enter Mobile Number' />
                    <span className='registration-checkbox'><input class='lg-checkbox' type="checkbox" id="termsofuse" name="termsofuse" />
                                            <label for="termsofuse"> I agree with the Terms of Use.</label></span>
                    <span className='registration-checkbox'><input class='lg-checkbox' type="checkbox" id="privacyandcookie" name="privacyandcookie" />
                                            <label for="privacyandcookie"> I have read and understand the privacy policy and cookie policy.</label></span>
                    <span className='registration-checkbox'><input class='lg-checkbox' type="checkbox" id="marketingmessages" name="marketingmessages" />
                                            <label for="marketingmessages"> Receipt of marketing messages (optional)</label></span>
                                <button className='login-btn'>Sign up</button>
                </div>
        </div>
    </div>
  )
}

export default Register;
