import React from 'react';

const LgProductGrid = () => {
  return (
    <div className='lg-produt-grid'>
        <span className='lg-category-name'>Refrigerator</span>
        <div className='lg-result-and-sort'>
            <div className='result-count'>
                Showing 77 Results
            </div>
            <div className='sort-by-optn'>
                SORT BY 
                <select className='sort-by-optn-dropdown'>
                    <option>New In</option>
                    <option>Lowest Price</option>
                    <option>Highest Price</option>
                    </select>
            </div>
        </div>


        {/* product */}
        <div className='row'>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/refrigerator-1.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/refrigerator.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/refrigerator-a-1.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/refrigerator-a.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-6 col-md-3 col-lg-3 product-grid-list'>
                <div className="product-box-list-page">
                    <span className="p-discount">
                        -20%
                    </span>
                    <div className="lg-img-container">
                        <div className="p-img">
                            <a href="/">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="/" className="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span className="p-price">Rs. 90,000</span>
                            <a href="/" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                </div>
            </div>
            
            
            

        </div>
        
        {/* product ends */}
    </div>
  )
}

export default LgProductGrid;
