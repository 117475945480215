import React from 'react';
import LgCheckoutForm from '../components/LgCheckoutForm';
import LgCheckoutSummary from '../components/LgCheckoutSummary';
    
const Checkout = () => {
  return (
    <div className='lg-checkingout'>
          <LgCheckoutForm />
          <LgCheckoutSummary />
    </div>
  )
}

export default Checkout;
