import React from 'react';

const LgSidebar = () => {
  return (
    <div className='my-lg-sidebar'>
      <span className='my-lg-sidebar-navigation-text'>Navigations</span>
      <ul className='my-lg-sidebar-navigations'>
        <li>Dashboard</li>
        <li>Account Information</li>
        <li>Order History</li>
        <li>Wishlists</li>
        <li>My Coupons</li>
        <li>Member Benefits</li>
        <li>Support</li>
      </ul>
    </div>
  )
}

export default LgSidebar;
