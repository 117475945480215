import React from 'react';

const LgCartSummary = () => {
  return (
    <div className='lg-cart-summary'>
      <div className='lg-cart-summary-row-divide'>
        <span className='lg-cart-summary-prices-title'>Subtotal</span>
        <span>Rs. 2,18,000</span>
      </div>
      <div className='lg-cart-summary-row-divide'>
        <span className='lg-cart-summary-prices-title'>Shipping</span>
        <span>Free</span>
      </div>
      <hr />
      <div className='lg-cart-summary-row-divide cart-summary-total'>
        <span>Total</span>
        <span>Rs. 2,18,000</span>
      </div>
      <a href='/checkout' className='login-btn' style={{ width:"100%",textAlign:"center" }}>Check Out</a>
    </div>
  )
}

export default LgCartSummary;
