import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import desc1 from '../images/product-features/GL-B257DLW3-LG-stunningly-stylish-D-new.jpg';
import desc2 from '../images/product-features/GL-B257DLW3-wifi-convertible-D.jpg';
import desc3 from '../images/product-features/GL-B257DLW3_D.jpg';

const LgProductDescSpec = () => {
    return (
        <>
        <div className='product-desc-spec'>
                <Tabs>
                    <TabList>
                    <Tab>Description</Tab>
                    <Tab>Product Specs</Tab>
                    <Tab>Reviews</Tab>
                    </TabList>

                    <TabPanel>
                     
                     <div className='section-for-product-desc'>
                        <div className='single-section-for-product-desc'>
                           <img className='single-section-for-product-desc-img' src={ desc1 } alt='single-section-desc' />
                        </div>
                        <div className='single-section-for-product-desc'>
                           <img className='single-section-for-product-desc-img' src={ desc2 } alt='single-section-desc' />
                        </div>
                        <div className='single-section-for-product-desc'>
                           <img className='single-section-for-product-desc-img' src={ desc3 } alt='single-section-desc' />
                        </div>
                     </div>
                     
                    </TabPanel>
                    <TabPanel>
                        <p>
                            
                            
            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="product-spec" itemprop="description">
               <tbody>
                  <tr>
                     <td class="specs-heading">Brand</td>
                     <td itemprop="brand">Midea</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">VOLTAGE</td>
                     <td itemprop="brand">220V-240V</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">Climate</td>
                     <td itemprop="brand">T</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">Net Weight	</td>
                     <td itemprop="brand">76 Kg</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">Gross Weight</td>
                     <td itemprop="brand">85 Kg</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">Dimensions</td>
                     <td itemprop="brand">83.5 x 63.5 x 177.5 [ WxDxH ] cm</td>
                  </tr>
                  <tr>
                     <td class="specs-heading">Capacity (Ltrs)</td>
                     <td itemprop="brand">482 Ltr.</td>
                  </tr>
               </tbody>
            </table>
         
                    </p>
                    </TabPanel>
                    <TabPanel>
                    <h2>Review Under Construction</h2>
                    </TabPanel>
                </Tabs>
                </div>
    </>
    )
}
    
export default LgProductDescSpec;
