import React from 'react';
import product1 from '../images/cart/GF-B6012MC_AMCQESL_EASL_SG_C-350x350.jpg';
import product2 from '../images/cart/OLED83C3PSA-OLED-TV-350v.jpg';

const LgCartProducts = () => {
    return (
        <div className='lg-cart-product-container'>
            {/* cart item starts */}
            <div className='lg-cart-products'>
                <div className='lg-cart-product-image'><img className='lg-cart-image' src={product1} alt='cart product' /></div>
                <div className='lg-cart-product-information'>
                    <h3>601L Multi Door Refrigerator with Inverter Linear Compressor in Matt Black</h3>
                    <span className='lg-cart-product-model'>OLED83C3PSA</span>
                </div>
                <div className='lg-cart-product-price'>
                    <div className='increment-and-buttons cart-increment'>
            <div className='increment-button'>
            <div className="counter-container">
                <div className="counter-quantity">
                    <a href="#" className="quantity__minus"><span>-</span></a>
                    <input name="quantity" type="text" className="quantity__input" value="1" />
                    <a href="#" className="quantity__plus"><span>+</span></a>
                </div>
                
                </div>
            </div>
            
                    </div>
                    
                    <span className='cart-page-pricing'>Rs. 1,20,000</span>
                </div>
            </div>
            {/* cart item ends */}
            {/* cart item starts */}
            <div className='lg-cart-products'>
                <div className='lg-cart-product-image'><img className='lg-cart-image' src={product2} alt='cart product' /></div>
                <div className='lg-cart-product-information'>
                    <h3>LG OLED evo C3 83 inch TV 4K Smart TV 2023 | Wall mounted TV | TV wall design | Ultra HD 4K resolution | AI ThinQ</h3>
                    <span className='lg-cart-product-model'>OLED83C3PSA</span>
                </div>
                <div className='lg-cart-product-price'>
                    <div className='increment-and-buttons cart-increment'>
            <div className='increment-button'>
            <div className="counter-container">
                <div className="counter-quantity">
                    <a href="#" className="quantity__minus"><span>-</span></a>
                    <input name="quantity" type="text" className="quantity__input" value="1" />
                    <a href="#" className="quantity__plus"><span>+</span></a>
                </div>
                
                </div>
            </div>
            
                    </div>
                    <span className='cart-page-pricing'>Rs. 98,000</span>
                </div>
            </div>
            {/* cart item ends */}
            </div>
  )
}

export default LgCartProducts;
