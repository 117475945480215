import React from 'react';

const LgMagazineSearch = () => {
  return (
    <div className='magazine-search-wrapper'>
        <div className='magazine-search-title'>
              Search
        </div>
          <div className='magazine-search-box'>
            <form className='magazine-search-form'>
                  <input className='magazine-search-box-input-text' type='text' placeholder='Enter the keyword' />
                  <input type='button' className='magazine-search-box-search-btn'/>
            </form>
        </div>
    </div>
  )
}

export default LgMagazineSearch;
