import React from 'react';
import LgCartProducts from '../components/LgCartProducts';
import LgCartSummary from '../components/LgCartSummary';

const LgCart = () => {
    return (
      <>
        {/* <div className='lg-cart-whole-container'> */}
            <span className='cart-heading'>Cart Items</span>
          <div className='cart-container'>
              <LgCartProducts />
              <LgCartSummary />
          </div>
          {/* </div> */}
            </>
  )
}

export default LgCart;
