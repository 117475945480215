import React from 'react';
import {
    FaTicketAlt,
    FaClipboardList,
    FaBox,
    FaCommentDots
} from "react-icons/fa";

const LgContentbar = () => {
  return (
    <div className='my-lg-contentbar'>
        <div className='my-lg-dashboard-grids'>
            <div className='my-lg-dashboard-grid-box'>
                <div className='my-lg-dashboard-grid-box-titles'>
                    <span className='my-lg-dashboard-grid-icon'>
                        <FaTicketAlt />
                    </span>
                    <span className='my-lg-dashboard-grid-title'>
                        My Coupons
                    </span>
                </div>
                <div className='my-lg-dashboard-grid-box-counters'>
                    <span className='my-lg-dashboard-grid-counter'>
                        4
                    </span>
                </div>
            </div>
            <div className='my-lg-dashboard-grid-box'>
                <div className='my-lg-dashboard-grid-box-titles'>
                    <span className='my-lg-dashboard-grid-icon'>
                        <FaClipboardList />
                    </span>
                    <span className='my-lg-dashboard-grid-title'>
                        My Orders
                    </span>
                </div>
                <div className='my-lg-dashboard-grid-box-counters'>
                    <span className='my-lg-dashboard-grid-counter'>
                        9
                    </span>
                </div>
            </div>
            <div className='my-lg-dashboard-grid-box'>
                <div className='my-lg-dashboard-grid-box-titles'>
                    <span className='my-lg-dashboard-grid-icon'>
                        <FaBox />
                    </span>
                    <span className='my-lg-dashboard-grid-title'>
                        My Products
                    </span>
                </div>
                <div className='my-lg-dashboard-grid-box-counters'>
                    <span className='my-lg-dashboard-grid-counter'>
                        0
                    </span>
                </div>
            </div>
            <div className='my-lg-dashboard-grid-box'>
                <div className='my-lg-dashboard-grid-box-titles'>
                    <span className='my-lg-dashboard-grid-icon'>
                        <FaCommentDots />
                    </span>
                    <span className='my-lg-dashboard-grid-title'>
                        My Enquiries
                    </span>
                </div>
                <div className='my-lg-dashboard-grid-box-counters'>
                    <span className='my-lg-dashboard-grid-counter'>
                        2
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LgContentbar;
