import React from 'react';
import LgMagazineBanner from '../components/Magazine/LgMagazineBanner';
import LgTrendTopics from '../components/Magazine/LgTrendTopics';
import LgMagazineSearch from '../components/Magazine/LgMagazineSearch';
import LgBlogGrids from '../components/Magazine/LgBlogGrids';

const Magazine = () => {
  return (
    <div>
          <LgMagazineBanner />
          <LgTrendTopics />
      <LgMagazineSearch />
      <LgBlogGrids />
    </div>
  )
}

export default Magazine;
