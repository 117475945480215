import React from 'react';
import welcomecoupon from '../images/login/icon-coupon-black-64-64.svg';
import delivery from '../images/login/icon-delivery-64-64.svg';
import exclusive from '../images/login/icon-exclusive-64-64.svg';
import {
    FaFacebook,
    FaGoogle,
    FaApple,
    FaAmazon
} from "react-icons/fa";

const Login = () => {
    return (
        
            <div className='login-container'>
                    <div className='login-container-boxes'>
                <h1>Sign into your LG account</h1>
                <h3>Take advantage of exclusive benefits for LG.com shoppers</h3>
                            <div className='login-container-sub-boxes'>
                                <span className='input-field-title'>Email Address <span className='required-section'>*</span></span>
                                <input className='lg-input' type='email' placeholder='Email account (case sensitive)' />
                                <span className='input-field-title'>Password <span className='required-section'>*</span></span>
                                <input className='lg-input' type='password' placeholder='Password (case sensitive)' />

                                <div className='lg-input-remember-me-and-forgot-password'>
                                        <div className='remember-me'>
                                            <input class='lg-checkbox' type="checkbox" id="rememberme" name="RememberMe" />
                                            <label for="RememberMe"> Remember email</label>
                                        </div>
                                        <div className='forgot-password'>
                                            Forgot Password
                                        </div>
                                </div>

                                <a href='/my-lg' className='login-btn'>Sign in</a>
                </div>
                    <div className='contine-with-option'>
                        <span className='continue-with-option-or'>OR</span>
                </div>
                <span className='sign-in-with-text'>Sign in with</span>
                <div className='sign-in-with-social'>
                    <span><FaFacebook /></span>
                    <span><FaGoogle /></span>
                    <span><FaApple /></span>
                    <span><FaAmazon /></span>
                </div>
                    </div>
            <div className='login-container-boxes'>
                <h1>Become an LG member</h1>
                <h3>Enjoy all the benefits of free LG membership, from special discounts to exclusive services and offers.</h3>
                            <div className='login-container-sub-boxes'>
                                <ul className='login-member-box-list'>
                                    <li className='login-member-box-li'>
                                        <div className='member-box-divide-row'>
                                            <div className='member-box-divide-column'>
                                                <span className='member-box-title'>Welcome Coupon</span>
                                                <span className='member-box-desc'>Enjoy $10 off on your first purchase when you sign up as an LG member
</span>
                                            </div>
                                            <div>
                                                <img src={welcomecoupon} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className='login-member-box-li'>
                                        <div className='member-box-divide-row'>
                                            <div className='member-box-divide-column'>
                                                <span className='member-box-title'>Exclusive pricing
</span>
                                                <span className='member-box-desc'>Receive an exclusive 3% membership discount for all orders during promotional event period
</span>
                                            </div>
                                            <div>
                                                <img src={exclusive} />
                                            </div>
                                        </div>
                                    </li>
                                    <li className='login-member-box-li'>
                                        <div className='member-box-divide-row'>
                                            <div className='member-box-divide-column'>
                                                <span className='member-box-title'>Free delivery
</span>
                                                <span className='member-box-desc'>On all orders purchased on LG.com
</span>
                                            </div>
                                            <div>
                                                <img src={delivery} />
                                            </div>
                                        </div>
                                    </li>
                    </ul>
                    <a href='/register' className='register-btn'>Sign up</a>
                            </div>
                    </div>
            </div>
        
  )
}

export default Login;
