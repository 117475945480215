import React from 'react';
import {
    FaArrowLeft,
    FaArrowRight
} from "react-icons/fa";
import grid1 from '../images/cat-grid/icon_bottom-freezer_64_normal.svg';
import grid2 from '../images/cat-grid/icon_fridge_freezers_64.svg';
import grid3 from '../images/cat-grid/icon_multi-door_64_normal.svg';
import grid4 from '../images/cat-grid/icon_refrigerator_64.svg';
import grid5 from '../images/cat-grid/icon_side-by-side_64_normal.svg';

const LgCategoryGridSlider = () => {
    return (
      <>
    <div className='category-grid-slider-container'>
                <section className='cat-grid-slider'>
                    
                    {/* <button aria-label="Previous" className="glider-prev grid-glider-prev">
                        <span><FaArrowLeft /></span>
                    </button>
                    <button aria-label="Next" className="glider-next grid-glider-next">
                        <span><FaArrowRight /></span>
                    </button> */}
                
            <div className="glider-contain">
                <div id="grid-glider" className="glider cat-grid-glide-auto-width">
                    {/* cat grid */}
                    <div className="cat-grid-box">
                        <div className="lg-img-container">
                            <div className="p-img">
                                <a href="#">
                            <img src={grid2} className="cat-grids" alt="Front" />
                                </a>
                            </div>
                        </div>
                        <div className="catgrid-p-box-text">
                            <a href="#" className="product-title">
                                LG Objet Collection - Fridge
                            </a>
                        </div>
                    </div>
                    {/* cat grid ends */}
                    {/* cat grid */}
                    <div className="cat-grid-box">
                        <div className="lg-img-container">
                            <div className="p-img">
                                <a href="#">
                            <img src={grid1} className="cat-grids" alt="Front" />
                                </a>
                            </div>
                        </div>
                        <div className="catgrid-p-box-text">
                            <a href="#" className="product-title">
                                Bottom Mount Freezers
                            </a>
                        </div>
                    </div>
                    {/* cat grid ends */}
                    {/* cat grid */}
                    <div className="cat-grid-box">
                        <div className="lg-img-container">
                            <div className="p-img">
                                <a href="#">
                            <img src={grid3} className="cat-grids" alt="Front" />
                                </a>
                            </div>
                        </div>
                        <div className="catgrid-p-box-text">
                            <a href="#" className="product-title">
                                Multi Door Refrigerators
                            </a>
                        </div>
                    </div>
                    {/* cat grid ends */}
                    {/* cat grid */}
                    <div className="cat-grid-box">
                        <div className="lg-img-container">
                            <div className="p-img">
                                <a href="#">
                            <img src={grid4} className="cat-grids" alt="Front" />
                                </a>
                            </div>
                        </div>
                        <div className="catgrid-p-box-text">
                            <a href="#" className="product-title">
                                Top Mount Freezers
                            </a>
                        </div>
                    </div>
                    {/* cat grid ends */}
                    {/* cat grid */}
                    <div className="cat-grid-box">
                        <div className="lg-img-container">
                            <div className="p-img">
                                <a href="#">
                            <img src={grid5} className="cat-grids" alt="Front" />
                                </a>
                            </div>
                        </div>
                        <div className="catgrid-p-box-text">
                            <a href="#" className="product-title">
                                Side-by-Side Refrigerators
                            </a>
                        </div>
                    </div>
                    {/* cat grid ends */}
                </div>
            </div>
            {/* test ends */}
        </section>
    </div>
    </>
  )
}

export default LgCategoryGridSlider;
