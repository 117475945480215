import React from 'react';
import thumb1 from '../../images/blogs/blog (1).jpg';
import thumb2 from '../../images/blogs/blog (2).jpg';
import thumb3 from '../../images/blogs/blog (3).jpg';
import thumb4 from '../../images/blogs/blog (4).jpg';
import thumb5 from '../../images/blogs/blog (5).jpg';
import thumb6 from '../../images/blogs/blog (6).jpg';
import thumb7 from '../../images/blogs/blog (7).jpg';


const LgBlogGrids = () => {
    return (
    <>
        <div className='blog-grids-wrapper'>
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb1 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>What factors to keep in mind before you buy an air purifier in Nepal?</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#AirPurifiers</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a href="#" className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb2 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>Water Purifiers: A Safeguard against Water-Borne Diseases</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#WaterPurifiers</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a  onClick={() => {
                    window.location.href = "/blogs";
                    }} className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb3 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>Curved vs Flat Monitors for Gaming: Which One Is Best for You?</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#computerproducts</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a  onClick={() => {
                    window.location.href = "/blogs";
                    }} className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb4 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>Refrigerator with Ice and Water Dispenser</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#Refrigerators</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a  onClick={() => {
                    window.location.href = "/blogs";
                    }} className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb5 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>Irresistible Reasons to Choose LG XBOOM Party Speakers for Your Indoor Celebrations</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#homeaudio</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a  onClick={() => {
                    window.location.href = "/blogs";
                    }} className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
            {/* Blog box starts */}
            <div className='blog-grid'>
                <div className='blog-grid-thumbnail-wrapper'>
                  <img className='blog-grid-thumbnail' alt='Blog Banner' src={ thumb6 } />
                </div>
                <div className='blog-grid-title'>
                    <h4>Microwave Oven vs. OTG Oven: Which Is Better for Your Cooking Needs?</h4>
                </div>
                <div className='blog-grid-date-wrapper'>
                    <span className='blog-grid-date-text'>11/30/2023</span>
                </div>
                <div className='blog-grid-tag-wrapper'>
                  <span className='blog-grid-tag'>#MicrowaveOvens</span>
                </div>
                <div className='blog-grid-learn-more-wrapper'>
                    <a onClick={() => {
                    window.location.href = "/blogs";
                    }} className="learn-more-btn">Learn More</a>
                </div>
            </div>
            {/* Blog box ends */}
        </div>
    </>
  )
}

export default LgBlogGrids;
