function LgBanner() {
  return (
    <div className='lg-banner-box'>
        <div className='lg-banner-sub-box-1'>
            <div className='title-and-caption'>
                <span className='lg-banner-sub-box-title'>
                    Roomandu
                </span>
                <span className='lg-banner-sub-box-caption'>
                    Search & Rent Your Perfect Room <br/>Click, Rent, Live: Find Your Dream Room in Vibrant Kirtipur.
                </span>
                <a href="#" className="learn-more-btn">Learn More</a>
            </div>
            <img src="./img/banners/banner-1.jpg" />
        </div>
        <div className='lg-banner-sub-box-2'>
            <div className='title-and-caption-2'>
                <span className='lg-banner-sub-box-title-2'>
                    Search Contacts
                </span>
                <span className='lg-banner-sub-box-caption-2'>
                    Numbers at Your Fingertips: Stay Connected in Kirtipur.
                </span>
                <a href="#" className="learn-more-btn">Learn More</a>
            </div>
            <img src="./img/banners/banner-2.jpg" />
        </div>
    </div>
  );
}

export default LgBanner;