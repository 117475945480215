import React from 'react';

const LgShopByFilter = () => {
  return (
    <div className='shop-by-filter'>
        <span className='filter-heads'>Shop By</span>
        <ul>
            <li>Refrigerator</li>
            <li>Single Door Refrigerator</li>
            <li>Double Door Refrigerator</li>
            <li>Side By Side Refrigerator</li>
            <li>Portable CHOTU-KOOL</li>
            <li>Multi Door Refrigerator</li>
            <li>Qube</li>
        </ul>
    </div>
  )
}

export default LgShopByFilter;
