import React from 'react';

const LgCategoryBanner = () => {
  return (
    <div className='lg-category-banner'>
        <img src='./img/ads/refrigerator-banner.jpg' alt='' />
    </div>
  )
}

export default LgCategoryBanner;
