import React from 'react';
import LgProductImage from '../components/LgProductImage';
import LgBreadcrumb from '../components/LgBreadcrumb';
import LgPeopleBuys from '../components/LgPeopleBuys';
import LgProductDescSpec from '../components/LgProductDescSpec';

const SingleProduct = () => {
  return (
    <>
    <LgBreadcrumb />
      <LgProductImage />
      <LgProductDescSpec />
      <LgPeopleBuys />
      
    </>
  )
}

export default SingleProduct;
