import React from 'react';
import magazineBanner from '../../images/magazine/magazine-banner.jpg';

const LgMagazineBanner = () => {
  return (
    <div className='magazine-banner'>
          <img src={ magazineBanner } className='magazine-banner-img' alt="Magazine Banner" />
    </div>
  )
}

export default LgMagazineBanner;
