import React, {useState} from 'react';
import {
    FaRegUser,
    FaSearch,
    FaShoppingCart,
    FaFacebookSquare,
    FaInstagramSquare,
    FaYoutubeSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import svglogo from '../logo.png';

function NavBar() {

    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <>
            <header className='mainhead'>
                <div className='logo'>
                        <a onClick={() => {
                    window.location.href = "/";
                    }}
                        className='link-pointer'
                    ><img alt='LG' src={svglogo} /></a>
                </div>

                <div className='navi_bar'>
                        <div className='nav_child top_nav'>
                            <div className='top-ko-socials'>
                                <a href="https://www.youtube.com/channel/UCpWikeQHPwjsLtqRYS5mabA" target="_blank"><FaYoutubeSquare size={20} /></a>
                                <a href="https://www.instagram.com/panga.kirtipur/" target="_blank"><FaInstagramSquare size={20} /></a>
                                <a href="https://facebook.com/kirtipur.panga" target="_blank"><FaFacebookSquare size={20} /></a>
                            </div>
                            <div className="top-ko-links">
                                <a href="#">Roomandu</a>
                                <a href="https://www.facebook.com/restore.photo.nepal/" target="_blank">Photo Restoration Nepal</a>
                            </div>
                        </div>
                    <div className='nav_child bottom_nav'>
                            <div className='main_menu'>
                                <div className='dropdown_menu'>
                                    <a onClick={() => {
                    window.location.href = "/saparu";
                                            }}
                                                className='link-pointer direct-menu'
                                            >
                                    <span className='desax' style={{ animationDelay:"0.5s" }}>SAPARU JATRA</span>
                                    </a>
                                </div>
                                {/* <div className='dropdown_menu'>
                                    <span className='desax'>SHOP</span>
                                </div>
                                <div className='dropdown_menu'>
                                    <span className='desax'>EAT</span>
                                </div>
                                <div className='dropdown_menu'>
                                    <span className='desax'>JOBS</span>
                                </div> */}
                                
                                {/* <div className='dropdown_menu'>
                                    <span className='desax' style={{ animationDelay:"0.2s" }}>EVENTS</span>
                                <div className='menu-content'>
                                    <div className='layers'>
                                        <div className='dynamic-menu-contents'>
                                    <div className='sublayers sublayers-for-2menu-width'>
                                        <h5><a onClick={() => {
                    window.location.href = "/products";
                                            }}
                                                className='link-pointer'
                                            >Jatras</a></h5>
                                        <hr/>
                                            
                                            <a href="#">Gai Jatra</a>
                                            <a href='#'>Saat Gaaule Jatra</a>
                                        </div>
                                    <div className='sublayers sublayers-for-2menu-width'>
                                        <h5><a onClick={() => {
                    window.location.href = "/products";
                                            }}
                                                className='link-pointer'
                                            >Festivals</a></h5>
                                        <hr/>
                                            <a href='#'>Nhu Da Vintuna</a>
                                            </div>

                                            <div className='sublayers sublayers-for-2menu-width'>
                                        
                                                <h5><a onClick={() => {
                    window.location.href = "/products";
                                            }}
                                                className='link-pointer'
                                            >Other Events</a></h5>
                                                <hr />
                                                <a href='#'>Musical Events</a>
                                                <a href='#'>Sports Events</a>
                                                <a href='#'>Foods Events</a>
                                                <a href='#'>Exhibitions</a>
                                            </div>
                                            <div className='sublayers sublayers-for-2menu-width'>
                                        <img src='img/navs/refrigerator-ad.jpg' alt='LG Nepal' />
                                            </div>
                                    <div className='sublayers sublayers-for-2menu-width'>
                                        <img src='img/navs/refrigerator-ad.jpg' alt='LG Nepal' />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div> */}
                                <div className='dropdown_menu'>
                                <a onClick={() => {
                    window.location.href = "/phonenumbers";
                                            }}
                                                className='link-pointer direct-menu'
                                            ><span className='desax' style={{ animationDelay:"0.3s" }}>PHONE NUMBERS</span></a>
                                </div>
                                {/* <div className='dropdown_menu'>
                                    <span className='desax' style={{ animationDelay:"0.4s" }}>PLACES TO VISIT</span>
                                </div>
                                <div className='dropdown_menu'>
                                    <span className='desax' style={{ animationDelay:"0.4s" }}>THINGS TO DO</span>
                                </div>
                                <div className='dropdown_menu'>
                                    <span className='desax' style={{ animationDelay:"0.5s" }}>FOODS TO EAT</span>
                                </div> */}
                            </div>
                            <div className='icon_menu'>
                            <div className='right-icon-menus-svg'>
                                <div className='login-btn-right-top'>
                                    <FaRegUser size={20} />
                                    <div className='login-signup-register-product'>
                                    <span><a href="/login">Sign in / Sign up</a></span>
                                    <span><a href="#">Become a Merchant</a></span>
                                            </div>
                                </div>
                                
                            </div>
                            <div className='right-icon-menus-svg'>
                                <a href="/cart"><FaShoppingCart size={20} /></a>
                            
                            </div>
                                <div className='right-icon-menus-svg'><a href="/"><FaSearch size={20}/></a></div>
                            </div>
                        </div>
                </div>

                <div id="myOverlay" className="overlay">
  <span className="closebtn" onClick="closeSearch()" title="Close Overlay">×</span>
  <div className="overlay-content">
    <form action="/action_page.php">
      <input type="text" placeholder="Search.." name="search" />
      <button type="submit"><i className="fa fa-search"></i></button>
    </form>
  </div>
</div>
                

            </header>

        {/* mobile menu */}
            <div className='mob-header'>
                <div className="row for-mobile  ">
                    <div className="top-menu">
                        <ul>
                            <li className="active"><a href="#"><span>Welcome to Kirtipur</span></a></li>
                            <li><a href="#" ><span>Contact Us</span></a></li>
                        </ul>
                    </div>
                    <div className="nav-wrap">
                        <div className="logo">
                            <a  onClick={() => {window.location.href = "/";}}
                                    className='link-pointer'>
                                        <img src={svglogo} alt="Kirtipur logo" />
                            </a>
                        </div>
                        <div className="menu" onClick={toggleNav}>
                            <a >
                                <span className="line line1"></span>
                                <span className="line line2"></span>
                                <span className="line line3"></span>
                                <span className="sr-only">Menu</span>
                            </a>                       				
                            <nav className="menu-wrap" style={{ display: isNavOpen ? 'block' : 'none' }}>
                                <ul className="depth1-m">
                                <li><a onClick={() => {
                    window.location.href = "/saparu";
                                            }}>SAPARU JATRA</a></li>
                                    {/* <li><a href="#">SHOP</a></li>
                                    <li><a href="#">EAT</a></li>
                                    <li><a href="#">JOBS</a></li>
                                    <li><a href="#">EVENTS</a></li> */}
                                    <li><a onClick={() => {window.location.href = "/phonenumbers";}}>PHONE NUMBERS</a></li>
                                    {/* <li><a href="#">THINGS TO DO</a></li>
                                    <li><a href="#">FOODS TO EAT</a></li>
                                    <li><a href="#">LOST+FOUND</a></li> */}
                                    
                                </ul>
                            </nav>
                        </div>
                        <div className="left">
                            <div className="icons">
                                <div className="search">
                                    <a href="#" className='link-pointer'>
                                            <span className="sr-only">Search</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="icons">
                                <div className="login">
                                    <a href="/login" className="before-login link-pointer">
                                        <span className="sr-only">Panga, Kirtpur</span>
                                    </a>				
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* mobile menu ends */}
      </>
    );
  }
  
  export default NavBar;
  