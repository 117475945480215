import React, { useState, useEffect } from "react";
import LgFilterAd from '../components/LgFilterAd';
import DataTable from 'react-data-table-component';
import axios from "axios";

const PhoneNumbers = () => {
  const [numbers, setNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchPhoneNumbers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://pangakirtipur.com.np/api/phonenumbers');
      setNumbers(response.data.data);
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  const LinkCell = ({ row }) => {
    return (
      <a href={row.link} target="_blank" rel="noopener noreferrer">
        {row.link}
      </a>
    );
  };

  const columns = [
    {
      name: 'Category',
      selector: row => row.company_id,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
      style: {
        fontWeight:"bold",
      },
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Contact 1',
      selector: row => row.contact1,
      wrap: true,
    },
    {
      name: 'Contact 2',
      selector: row => row.contact2,
      wrap: true,
    },
    {
      name: 'Website',
      selector: row => <LinkCell row={row} />,
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        fontSize:"16px",
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize:"16px",
        color:"#fff",
        background:"#cc0041",
      },
    },
    cells: {
      style: {
        fontSize:"16px",
      },
    },
  };

  const filteredNumbers = numbers.filter((row) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      row.company_id && row.company_id.toLowerCase().includes(searchTerm) ||
      row.name && row.name.toLowerCase().includes(searchTerm) ||
      row.address && row.address.toLowerCase().includes(searchTerm) ||
      row.contact1 && row.contact1.toLowerCase().includes(searchTerm) ||
      row.contact2 && row.contact2.toLowerCase().includes(searchTerm) ||
      row.link && row.link.toLowerCase().includes(searchTerm)
    );
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleError = (error) => {
    console.error('Error:', error);
  };

  return (
    <>
      <div className='cat-page-title-and-desc'>
        <h1>Search Contacts</h1>
        <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder="Search phone number for 'Ambulance', 'Bank', 'Hotel', 'Police', etc..."
                      className="phone-number-search-box"
                    />
      </div>
      <div className='divide-products-filters'>
          <div className='products'>
                
                <div className='phone-numbers-search-container'>
                  <DataTable
                    columns={columns}
                    data={searchQuery ? filteredNumbers : numbers}
                    progressPending={isLoading}
                    error={isLoading ? undefined : handleError}
                    customStyles={customStyles}
                    pagination
                    filtering
                  />
                </div>
          </div>
            <div className='filters'>
                <LgFilterAd />
            </div>
            
      </div>
    </>
  )
}

export default PhoneNumbers;
