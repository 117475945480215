import React from 'react';
import latest1 from '../images/latest-products/GP1-HA-LG-HOME-02-1-D-2023SG.jpg';
import latest2 from '../images/latest-products/GP1-HA-LG-HOME-02-2-D-2023(SG).jpg';
import latest3 from '../images/latest-products/GP1-HA-LG-HOME-02-3-D-2023(SG).jpg';

const LgLatestProduct = () => {
    return (
        <div className='our-latest-products'>
            <span className='out-latest-products-heading'>Explore Kirtipur</span>
            <div className='lg-box-ft'>
                <div class="lg-sub-box-ft">
                    <img src={latest1} alt="LG Latest Products" />
                    <div className='latest-product-title-and-button'>
                        <span className='latest-product-title'>PLACES TO VISIT</span>
                        <a href="#" class="learn-more-btn" style={{ marginTop:"10px" }}>Learn More</a>
                    </div>
                </div>
                <div class="lg-sub-box-ft">
                    <img src={latest2} alt="LG Latest Products" />
                    <div className='latest-product-title-and-button'>
                        <span className='latest-product-title'>FOODS TO EAT</span>
                        <a href="#" class="learn-more-btn" style={{ marginTop:"10px" }}>Learn More</a>
                    </div>
                </div>
                <div class="lg-sub-box-ft">
                    <img src={latest3} alt="LG Latest Products" />
                    <div className='latest-product-title-and-button'>
                        <span className='latest-product-title'>LODGES TO SHELTER</span>
                        <a href="#" class="learn-more-btn" style={{ marginTop:"10px" }}>Learn More</a>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default LgLatestProduct;
