import React from 'react';

const LgTrendTopics = () => {
    return (
    <>
        <div className='trend-topics-wrapper'>
                <div className='trend-topics-title'>
                    <h3>Trend Topics</h3>
                </div>
                <div className='trend-topics-hashtags'>
                    <span>#tv</span>
                    <span>#homeaudio</span>
                    <span>#refrigerators</span>
                    <span>#computerproducts</span>
                    <span>#Dishwashers</span>
                    <span>#Water Purifiers</span>
                    <span>#Microwave Ovens</span>
                    <span>#Washing Machines</span>
                    <span>#AC and Fans</span>
                    <span>#Cloth Steamer</span>
                    <span>#Vacuum Cleaners</span>
                    <span>#Air Purifiers</span>
                    <span>#Promotional Offers</span>
                    <span>#Innovations</span>
                </div>
        </div>
    </>
  )
}

export default LgTrendTopics;
