import React from 'react';
import {
    FaArrowRight,
    FaPlusCircle,
    FaTv,
    FaCartPlus,
    FaTools,
    FaComment,
    FaWhatsapp,
    FaRegEnvelope
} from "react-icons/fa";

const LgHelp = () => {
  return (
      <div className='need-help'>
          <div className='need-help-first-row'>
            <div className='need-help-first-row-first-col'>
                <span class="out-latest-products-heading">Need Help?</span>
                  <span class="need-help-subtitle">We're here to provide all the help you need.</span>
              </div>
              <div className='need-help-first-row-second-col'>
                  <a href="#" className='support-btn'>Get Support</a>
              </div>
          </div>
          <div className='need-help-second-row'>
              <ul className='need-help-support-ul'>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Business Registration
                                    </span>
                                    <span className='support-contents-desc'>
                                        Registering your business in our portals.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaPlusCircle />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Advertise with Us
                                    </span>
                                    <span className='support-contents-desc'>
                                        Show your Ads in our portals.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaTv />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Shop / Sell with Us
                                    </span>
                                    <span className='support-contents-desc'>
                                        Shop our desired products or Sell your business with us.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaCartPlus />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Repair request
                                    </span>
                                    <span className='support-contents-desc'>
                                        Request repair service conveniently online.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaTools />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Live chat
                                    </span>
                                    <span className='support-contents-desc'>
                                        Chat with our Experts for assistance in real time.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaComment />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        WhatsApp
                                    </span>
                                    <span className='support-contents-desc'>
                                        Chat with our Support using the most popular messenger.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaWhatsapp />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Email us
                                    </span>
                                    <span className='support-contents-desc'>
                                        Send an Email to Panga, Kirtipur.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaRegEnvelope />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  <li>
                      <a href="#" className='support-links'>
                          <div className='support-encloser'>
                                <div className='support-contents'>
                                    <span className='support-contents-title'>
                                        Send us Feedbacks
                                    </span>
                                    <span className='support-contents-desc'>
                                        We want to hear from you to improvise our portals.
                                    </span>
                                </div>
                            
                                <div className='support-image'>
                                    <FaRegEnvelope />
                              </div>
                            </div>
                          <div className='support-arrow'>
                              <FaArrowRight />
                          </div>
                      </a>
                  </li>
                  
              </ul>
          </div>
    </div>
  )
}

export default LgHelp;
