import React from 'react';
import LgBreadcrumb from '../components/LgBreadcrumb';
import BlogBanner from '../components/Blogs/BlogBanner';
import BlogContent from '../components/Blogs/BlogContent';

const Blog = () => {
    return (
        <>  
        <LgBreadcrumb />
            <BlogBanner />
            <BlogContent />
    </>
  )
}

export default Blog;
