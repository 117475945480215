import React, { useState, useEffect } from 'react';
import LgCarousel from '../components/LgCarousel';
import LgBox from '../components/LgBox';
import LgBanner from '../components/LgBanner';
import LgFeaturedProduct from '../components/LgFeaturedProduct';
import LgCarouselMob from '../components/LgCarouselMob';
import LgFeatureProductStory from '../components/LgFeatureProductStory';
import LgLatestProduct from '../components/LgLatestProduct';
import SlickTest from '../components/SlickTest';

const Home = () => {

  const [orientation, setOrientation] = useState('portrait');

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(window.innerWidth < window.innerHeight ? 'portrait' : 'landscape');
    };

    updateOrientation();

    window.addEventListener('resize', updateOrientation);

    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, [window.innerWidth, window.innerHeight]);


  return (
    <>
      {orientation === 'portrait' ? <LgCarouselMob /> : <LgCarousel />}
      <LgBox />
      <LgBanner />
      <LgFeatureProductStory />
      {/* <LgFeaturedProduct /> */}
      <LgLatestProduct />
    </>
  )
}

export default Home;
