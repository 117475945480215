import React from 'react';

const LgPriceFilter = () => {
  return (
    <div className='shop-by-price'>
      <span className='filter-heads'>Price</span>
      {/* filter */}
      <div className="price-input">
        <div className="field">
          <span>Min</span>
          <input type="number" className="input-min" value="2500" />
        </div>
        <div className="separator">-</div>
        <div className="field">
          <span>Max</span>
          <input type="number" className="input-max" value="7500" />
        </div>
      </div>
      <div className="slider">
        <div className="progress"></div>
      </div>
      <div className="range-input">
        <input type="range" className="range-min" min="0" max="10000" value="2500" step="100" />
        <input type="range" className="range-max" min="0" max="10000" value="7500" step="100" />
      </div>
      {/* filter ends */}
    </div>
  )
}

export default LgPriceFilter;
