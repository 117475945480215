import React from 'react';
import LgSidebar from '../components/UserDashboard/LgSidebar';
import LgContentbar from '../components/UserDashboard/LgContentbar';
import { FaChevronRight } from 'react-icons/fa';

const UserDashboard = () => {
    return (
        <>
            <span className='welcome-user-text'>Hi Vicky Maharjan <FaChevronRight style={{ fontSize:"12px" }} /></span>
            <div className='my-lg-dashboard'>
                <LgSidebar />
                <LgContentbar />
            </div>
        </>
  )
}

export default UserDashboard;
