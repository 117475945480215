import React from 'react';

const Saparu = () => {
    return (
        <>  
        <div className='blog-grids-wrapper'>
            <div className='saparu-route'>
                <div className='complain-box-form-title'>
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1T1wbzO8BkpEDOJz2E_S0BTjcfFMU1JQ&ehbc=2E312F&noprof=1" class='saparu-embed'></iframe>
                </div>
            </div>
        </div>
        </>
  )
}

export default Saparu;
