import React from 'react';
import banner from '../../images/blogs/monitorandac-desktop.jpg';

const BlogBanner = () => {
  return (
      <div className='blog-banner'>
          <div className='blog-banner-title-and-date'>
                <span className='blog-banner-title'>Curved vs Flat Monitors for Gaming: Which One Is Best for You?</span><br/>
                <span className='blog-banner-date'>11/30/2023</span>

          </div>
          
          <img className='blog-banner-img' src={banner} alt="LG Blog Banner" />
    </div>
  )
}

export default BlogBanner;
