import React from 'react';

const LgFilterAd = () => {
  return (
    <div className='filter-ad'>
        <img src='./img/ads/refrigerator-ad.jpg' alt='' />
    </div>
  )
}

export default LgFilterAd;
