import React from 'react';
import LgShopByFilter from '../components/LgShopByFilter';
import LgBreadcrumb from '../components/LgBreadcrumb';
import LgPriceFilter from '../components/LgPriceFilter';
import LgCapacityFilter from '../components/LgCapacityFilter';
import LgFilterAd from '../components/LgFilterAd';
import LgCategoryBanner from '../components/LgCategoryBanner';
import LgProductGrid from '../components/LgProductGrid';
import LgCategoryGridSlider from '../components/LgCategoryGridSlider';

const Products = () => {
  return (
    <>
      <LgBreadcrumb />
      <div className='cat-page-title-and-desc'>
        <h1>InstaView Door-in-Door™ Refrigerators</h1>
        <p>Take out the food from the refrigerator, not cold air with Instaview Door-In-Door Refrigerator. Knock twice, see inside and pick your favourite food without losing cool air. Check prices online and buy now.</p>
      </div>
      <LgCategoryGridSlider />
      <LgCategoryBanner />
        <div className='divide-products-filters'>
        <div className='filters'>
                
                <LgShopByFilter />
                <LgPriceFilter />
                <LgCapacityFilter />
                <LgFilterAd />
            </div>
            <div className='products'>
                
                <LgProductGrid />
            </div>
        </div>
    </>
  )
}

export default Products;
