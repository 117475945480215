function LgPeopleBuys() {
    return (
        <>
        <section class='p-slider' style={{ borderBottom:"none" }}>
            
            <div className="featured-title-btns">
            <span className="featured-product-heading">People Also Buy</span>
                <div className="slider-btns">
                    <button aria-label="Previous" class="glider-prev">
                        <span></span>
                    </button>
                    <button aria-label="Next" class="glider-next">
                        <span></span>
                    </button>
                </div>
                </div>
            <div class="glider-contain">
                <div class="glider">

                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}
                    {/* product */}
                    <div className="product-box">

                    <span className="p-discount">
                        -20%
                    </span>

                    <div class="lg-img-container">
                        <div className="p-img">
                            <a href="#">
                                <img src="./img/products/sample-product.jpg" className="p-img-front" alt="Front" />
                                <img src="./img/products/sample-product-back.jpg" className="p-img-back" alt="Back" />
                            </a>
                        </div>
                    </div>

                    <div className="p-box-text">
                        <div className="product-category">
                            <span>Washing Machine</span>
                        </div>
                        <a href="#" class="product-title">
                            8kg, AI Direct Drive Front Load Washing Machine
                        </a>

                        <div className="price-buy">
                            <span class="p-price">Rs. 90,000</span>
                            <a href="#" className="p-buy-btn">Buy Now</a>
                        </div>
                    </div>
                    </div>
                    {/* product ends */}

                </div>

            </div>
            {/* test ends */}
        </section>

        </>
    );
  }
  
  export default LgPeopleBuys;