import LgHelp from "./LgHelp";
import svglogo from '../logo.png';

function Footer() {
  return (
      <>
          <LgHelp />
    <div className="main_footer">
        <span className="main_footer-text">
        While we strive to provide the most accurate information possible, please be aware that  privacy policies, prices, promotions, information, and availability may vary depending on the specific service or listing you're viewing on our website. It's always recommended to check directly with the source for the most up-to-date details.
        </span>
        <hr />
        <div className="main_footer_caution">
            <span className="main_footer-text_long">
            Panga Kirtipur respects the privacy of its users. We collect information like contact details and search history to provide the best experience. This information is never shared with third parties without your consent. We may use anonymized data to improve the website's features and content. You have the right to access and update your information at any time. We implement reasonable security measures to protect your data, but please be aware that no website can guarantee complete data security. We recommend reviewing this policy periodically, as we may update it to reflect changes in how we handle user data.
            </span>
            <a href="javascript:void(0)" id="caution-btn" class="caution-btn">More</a>
        </div>
        <hr/>

        <div className="footer_categories">
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">SHOP</span>
            </div>
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">JOBS</span>
                <ul>
                    <li>Search for Jobs</li>
                    <li>Hire Employee</li>
                </ul>
            </div>
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">EVENTS</span>
                <ul>
                    <li>Jatras</li>
                    <li>Festivals</li>
                    <li>Musical Events</li>
                    <li>Sports Events</li>
                    <li>Foods Events</li>
                    <li>Exhibitions</li>
                </ul>
            </div>
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">Phone Directory</span>
                <ul className="ul-sub">
                    <li><a onClick={() => {window.location.href = "/products";}} className='link-pointer'>Search Phone Numbers</a></li>
                    <li>Add your Business</li>
                </ul>
            </div>
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">Roomandu</span>
                <ul className="ul-sub">
                    <li>Search Room</li>
                    <li>Rent Room</li>
                </ul>
            </div>
            <div className="footer_categories_column">
                <span className="footer_categories_column_heading">About Kirtipur</span>
                <ul className="ul-sub">
                    <li>Places to Visit</li>
                    <li>Things to Do</li>
                    <li>Foods to Eat</li>
                </ul>
                      <span className="footer_categories_column_heading heading-gap">LOST+FOUND</span>
            </div>
        </div>
    </div>

    <div className="visible-mobile">
    <span className="main_footer-text">
    While we strive to provide the most accurate information possible, please be aware that  privacy policies, prices, promotions, information, and availability may vary depending on the specific service or listing you're viewing on our website. It's always recommended to check directly with the source for the most up-to-date details.
        </span>
        <hr />
        <div className="main_footer_caution">
            <span className="main_footer-text_long">
            Panga Kirtipur respects the privacy of its users. We collect information like contact details and search history to provide the best experience. This information is never shared with third parties without your consent. We may use anonymized data to improve the website's features and content. You have the right to access and update your information at any time. We implement reasonable security measures to protect your data, but please be aware that no website can guarantee complete data security. We recommend reviewing this policy periodically, as we may update it to reflect changes in how we handle user data.
            </span>
            <a href="javascript:void(0)" id="caution-btn-mobile" class="caution-btn">More</a>
        </div>
   <div className="footer-component">
      <div className="footer-contents">
         <div className="footer-contents-box site-list">
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>SHOP</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>JOBS</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>EVENTS</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a onClick={() => {window.location.href = "/phonenumbers";}} className='link-pointer'><span>PHONE DIRECTORY</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>ROOMANDU</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>PLACES TO VISIT</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>THINGS TO DO</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>FOOTS TO EAT</span></a></span>
            </div>
            <div className="link-wrap">
               <span className="depth-1 depth-category"><a href="#"><span>LOST+FOUND</span></a></span>
            </div>
         </div>
      </div>
   </div>

   
</div>


    <div className="bottom_footer">
        <div className="bottom_footer_sub_box">
            <div className="bottom_footer_sub_box_top">
                <ul>
                    <li>Sitemap</li>
                    <li>Privacy Policy</li>
                    <li>Cookie Policy</li>
                    <li>Legal</li>
                </ul>
            </div>
            <div className="bottom_footer_sub_box_top">
                <span className="bottom_footer_sub_box_top_copyright">Copyright © {(new Date().getFullYear())} Panga, Kirtipur. All Rights Reserved.</span>
                <span className="bottom_footer_sub_box_top_official">Designed & Developed by <a href="https://vickymaharjan.com.np" style={{ color:"white" }} target="_blank">Vicky</a>.</span>
            </div>
        </div>
        <div className="bottom_footer_sub_box">
            <div className="lg-jeong-do">
                <div className="lg-jeong-do-left">
                          <img style={{width:"150px"}} src={svglogo} />
                </div>
            </div>
        </div>
        
          </div>
          
    
          
</>
  );
}

export default Footer;