import React from 'react';

const LgBreadcrumb = () => {
  return (
    <div className='lg-breadcrumb'>
        <ul>
            <li>Home</li>
            <li>Kitchen Appliances</li>
            <li>Refrigerator</li>
        </ul>
    </div>
  )
}

export default LgBreadcrumb;
