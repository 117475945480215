import { FaTruck, FaDollarSign, FaClock } from "react-icons/fa";

function LgBox() {
  return (
    <div className='lg-box'>
        <div className='lg-sub-box'>
            <div className="lg-super-sub-box-icon">
                <FaTruck size={30} />
            </div>
            <div className="lg-super-sub-box">
                <div className="lg-super-duper-sub-box">
                    <span className="lg-box-title">All-in-One: Your Pocket Guide to the City</span>
                </div>
                <div className="lg-super-duper-sub-box lg-box-description">
                From finding the perfect meal to exploring hidden gems, Panga Kirtipur connects you to everything your vibrant city has to offer..
                </div>
            </div>
        </div>
        <div className='lg-sub-box'>
            <div className="lg-super-sub-box-icon">
                <FaDollarSign size={40} />
            </div>
            <div className="lg-super-sub-box">
                <div className="lg-super-duper-sub-box">
                    <span className="lg-box-title">Simplify Your Kirtipur Experience</span>
                </div>
                <div className="lg-super-duper-sub-box lg-box-description">
                    Stop juggling multiple apps. Simplify your day with Panga Kirtipur, your one-stop shop for everything Kirtipur.
                </div>
            </div>
        </div>
        <div className='lg-sub-box'>
            <div className="lg-super-sub-box-icon">
                <FaClock size={40} />
            </div>
            <div className="lg-super-sub-box">
                <div className="lg-super-duper-sub-box">
                    <span className="lg-box-title">Local Events, Jobs, and Hidden Gems</span>
                </div>
                <div className="lg-super-duper-sub-box lg-box-description">
                    Uncover Kirtipur's hidden charm, explore exciting events, and find your dream job - all with Panga Kirtipur.
                </div>
            </div>
        </div>
    </div>
  );
}

export default LgBox;