import React from 'react';

const LgCapacityFilter = () => {
  return (
    <div className='shop-by-capacity'>
      <span className='filter-heads'>Capacity</span>
      <span className='filter-sub-heads'>Capacity in Ltrs</span>
      <ul>
            <li><input type="checkbox" /> <span>135 Ltrs</span> <span className='capacity-count'>2</span></li>
            <li><input type="checkbox" /> 170 Ltrs <span className='capacity-count'>2</span></li>
            <li><input type="checkbox" /> 185 Ltrs <span className='capacity-count'>4</span></li>
            <li><input type="checkbox" /> 190 Ltrs <span className='capacity-count'>3</span></li>
            <li><input type="checkbox" /> 192 Ltrs <span className='capacity-count'>5</span></li>
            <li><input type="checkbox" /> 195 Ltrs <span className='capacity-count'>2</span></li>
            <li><input type="checkbox" /> 230 Ltrs <span className='capacity-count'>9</span></li>
            <li><input type="checkbox" /> 231 Ltrs <span className='capacity-count'>2</span></li>
            <li><input type="checkbox" /> 240 Ltrs <span className='capacity-count'>4</span></li>
            <li><input type="checkbox" /> 258 Ltrs <span className='capacity-count'>1</span></li>
            <li><input type="checkbox" /> 260 Ltrs <span className='capacity-count'>8</span></li>
        </ul>
    </div>
  )
}

export default LgCapacityFilter;
