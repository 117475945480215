import { Carousel } from 'react-carousel-minimal';

function LgCarouselMob() {
    const mobdata = [
    {
      image: "./img/lg-carousel/mob-slider-0.jpg",
    },
  ];

  const captionStyle1 = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle1 = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App mobile-carousel" >
      <div style={{ textAlign: "center"}}>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={mobdata}
            time={3000}
            width="100%"
            height="769px"
            captionStyle={captionStyle1}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle1}
            captionPosition="bottom"
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "100%",
              maxHeight: "769px",
              margin: "10px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LgCarouselMob;