import React from 'react';
import { ImagesProvider, ProductImages } from "react-product-image";
import {
  FaExchangeAlt,
  FaRegHeart,
  FaFacebookSquare,
  FaTwitterSquare,
  FaPinterestSquare,
  FaCalculator,
  FaTruck,
  FaShareAlt,
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
} from "react-icons/fa";
import StickyBox from "react-sticky-box";

const LgProductImage = () => {
  return (
    <ImagesProvider
    thumbUrls={[
      "https://www.cgdigital.com.np/api/images/products/2Qtx8N_1665921152-2.png",
      "https://www.cgdigital.com.np/api/images/products/FBquhC_1665921152-7.png",
      "https://www.cgdigital.com.np/api/images/products/eKgBDC_1665921152-11.png",
    ]}
    imageUrls={[
      "https://www.cgdigital.com.np/api/images/products/2Qtx8N_1665921152-2.png",
      "https://www.cgdigital.com.np/api/images/products/FBquhC_1665921152-7.png",
      "https://www.cgdigital.com.np/api/images/products/eKgBDC_1665921152-11.png",
    ]}
  >
      <div className="image-container">
        <StickyBox offsetTop={100} offsetBottom={20}  style={{ alignSelf: "flex-start" }}>
        <div className='for-image-and-thumbnails'>
        <ProductImages />
        </div>
      </StickyBox>
        <div className="product-content-container">
          <div className='compare-and-wishlists-and-share'>
            <div className='compare-and-wishlist'>
              
            </div>
            <div className='share-product'>
                <span className='compare'><FaShareAlt className='comparewishlist' /></span>
              <span className='wishlist'><FaRegHeart className='comparewishlist' /></span>
              <span className='calculateemi'><FaCalculator className='comparewishlist' /></span>
            </div>
          </div>
          <div className='coupon-sects'>
            <span>SPECIAL COUPON</span>
            <span>SPECIAL DEAL MODEL</span>
          </div>
        <h1 className='single-product-name'>650 Ltr, Convertible Side-by-Side Refrigerator with Smart Inverter Compressor, Hygiene Fresh+™, DoorCooling+™, Smart Diagnosis™, Shiny Steel Finish</h1>
        <p>
          <strong>Model: <font color="#cc0041">GS-X6172MC</font></strong>
        </p>
          <p>
            <FaStar className='review-star' />
            <FaStar className='review-star' />
            <FaStar className='review-star' />
            <FaStarHalfAlt className='review-star' />
            <FaRegStar className='review-star' />
            <span className='write-a-review'>Write a review</span>
        </p>
        <p className='single-product-desc'>
            <span className='key-features'>Key Features</span>
            <ul className='product-key-features'>
            <li>Linear Cooling™</li>
            <li>Door Cooling+™</li>
        <li>Ultra Sleek Door</li>
        <li>Large Capacity</li>
        <li>ThinQ™</li>
        <li>Smart Inverter Compressor™</li>
        <li>Finish (Door) PZ</li>
        <li>Handle Type-Contour/Flat Door</li>
        <li>Display LED88</li>
        <li>Temp. Control-Child Lock</li>
        <li>Cooling system non frost</li>
        <li>Refrigerant-Linear Compressor BMH130NCMV</li>
        <li>Dispenser - Water Dispenser</li>
        <li>Energy Consumption 639kwh</li>
              <li>2 Years Comprehensive, including 10 Years on Compressor</li>
              </ul>
              [Excluding Plastic/Glassware, Gasket, Light Bulb/Tube]
          </p>
          <p>
            <span className='key-features'>Choose your Size</span>
            <div className='product-size-select-wrapper'>
              <button className='product-size-select-button'>164cm (65)</button>
              <button className='product-size-select-button'>139cm (55)</button>
              <button className='product-size-select-button'>108cm (43)</button>
            </div>
          </p>
        <p className='single-product-desc'>
        
            <span className='single-product-desc-listings'><FaTruck /> Get 5% off on your first purchase</span>
            <span className='single-product-desc-listings'><FaTruck /> LG Certified Installation and Free Shipping available</span>
            <span className='single-product-desc-listings'>* Cash Back upto Rs.12000 on NCEMI</span>
            
          </p>
          <StickyBox className='scrollable-addtocart' offsetTop={10} offsetBottom={10} bottom style={{ alignSelf: "flex-end" }}>
            
            <div className='price-section'>
              <span className='pricing-addtocart-total-title'>TOTAL</span>
              <span className='pricing-addtocart'>NRS. 70,000</span>
            </div>
        <div className='increment-and-buttons'>
            {/* <div className='increment-button'>
            <div className="counter-container">
                <div className="counter-quantity">
                    <a href="#" className="quantity__minus"><span>-</span></a>
                    <input name="quantity" type="text" className="quantity__input" value="1" />
                    <a href="#" className="quantity__plus"><span>+</span></a>
                </div>
                
                </div>
            </div> */}
            <div className='add-to-cart-button'>
                <button className='addtocart'>Add to Cart</button>
            </div>
            {/* <div className='buy-now-button'>
              <button className='buynow'>Buy Now</button>
            </div> */}
            </div></StickyBox>
          <div className='add-to-cart-button' style={{ marginTop:'10px' }}>
                <button className='wheretobuybutton'>Where to Buy</button>
            </div>
          <div className='add-to-cart-button' style={{ marginTop:'10px' }}>
                <button className='wheretobuybutton'>Product Enquiry</button>
            </div>
          
          
      </div>
    </div>
  </ImagesProvider>
  )
}

export default LgProductImage;
