import React from 'react';

const LgCheckoutSummary = () => {
  return (
    <div className='checkout-summary'>
          <h3>Cart Summary</h3>
          <div className='lg-cart-summary-row-divide'>
              <span>
                    Sub Total   
              </span>
              <span>
                    Rs. 2,20,000
              </span>
          </div>
          <hr />
          <div className='lg-cart-summary-row-divide'>
              <span>
                    Discount   
              </span>
              <span>
                    Rs. 10,000
              </span>
          </div>
          <hr/>
          <div className='lg-cart-summary-row-divide cart-summary-total'>
              <span>
                    Total   
              </span>
              <span>
                    Rs. 2,10,000
              </span>
          </div>
    </div>
  )
}

export default LgCheckoutSummary;
