import React from 'react';

const LgCheckoutForm = () => {
  return (
    
    <div className='checkout-form-container'>
      <h2>Billing Details</h2>
      <form>
          <span className='input-field-title'>Full Name <span className='required-section'>*</span></span>
                      <input className='lg-input lg-email-input' type='text' placeholder='Enter Full Name' />
          <span className='input-field-title'>Email Address <span className='required-section'>*</span></span>
      <input className='lg-input lg-email-input' type='email' placeholder='Email account (case sensitive)' />
        <div className='lg-form-address-divide'>
          <fieldset>
        <legend>Billing Address</legend>
          <span className='input-field-title'>Province <span className='required-section'>*</span></span>
          <select className='lg-input lg-email-input'>
              <option default>- Select Province -</option>
              <option>Province 1</option>
              <option>Province 2</option>
              <option>Province 3</option>
              <option>Province 4</option>
              <option>Province 5</option>
          </select>
          <span className='input-field-title'>State <span className='required-section'>*</span></span>
          <select className='lg-input lg-email-input'>
              <option default>- Select State -</option>
              <option>Sample State 1</option>
              <option>Sample State 2</option>
              <option>Sample State 3</option>
              <option>Sample State 4</option>
              <option>Sample State 5</option>
          </select>
          <span className='input-field-title'>Location <span className='required-section'>*</span></span>
            <input className='lg-input lg-email-input' type='text' placeholder='Enter Location' />
            <input type="checkbox" id="same-address" name="same-address" value="same-address" />
        <label for="same-address" style={{ marginLeft:"5px" }}> Billing and Shipping Address are same.</label>
        </fieldset>
          <fieldset>
        <legend>Shipping Address</legend>
          <span className='input-field-title'>Province <span className='required-section'>*</span></span>
          <select className='lg-input lg-email-input'>
              <option default>- Select Province -</option>
              <option>Province 1</option>
              <option>Province 2</option>
              <option>Province 3</option>
              <option>Province 4</option>
              <option>Province 5</option>
          </select>
          <span className='input-field-title'>State <span className='required-section'>*</span></span>
          <select className='lg-input lg-email-input'>
              <option default>- Select State -</option>
              <option>Sample State 1</option>
              <option>Sample State 2</option>
              <option>Sample State 3</option>
              <option>Sample State 4</option>
              <option>Sample State 5</option>
          </select>
          <span className='input-field-title'>Location <span className='required-section'>*</span></span>
        <input className='lg-input lg-email-input' type='text' placeholder='Enter Location' />
        </fieldset>
        </div>
        
          <hr />
          <h4 style={{ fontWeight:"bold" }}>Payment Details</h4>
          <span className='input-field-title'>Payment Method <span className='required-section'>*</span></span>
          <div className='payment-options'>
              <span>
                <input className='input-field-title paymentradio' type="radio" id="cod" name="payment" value="cod" />
                  <label for="cod"> Cash on Delivery</label>
                  </span>
              <span>
                <input className='input-field-title paymentradio' type="radio" id="esewa" name="payment" value="esewa" />
                  <label for="esewa"> eSewa</label>
                  </span>
              <span>
                <input className='input-field-title paymentradio' type="radio" id="bank" name="payment" value="bank" />
                  <label for="bank"> Bank Transfer</label>
                  </span>
              </div>
        <button className='login-btn'>Place Order</button>
        </form>
      </div>
      
  )
}

export default LgCheckoutForm;
