import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import story1 from '../images/product-story/PD0046-1-banner-01-D.jpg';
import story2 from '../images/product-story/GP1-HA-Sub-Category-Freezer-6-D-2023(UK).jpg';
import story3 from '../images/product-story/mnt-category-02-1-gaming-d.jpg';
import col1img from '../images/featured-3-col/PD0046-2-banner-02.jpg';
import col2img from '../images/featured-3-col/PD0046-2-banner-03.jpg';
import col3img from '../images/featured-3-col/PD0046-2-banner-05.jpg';
import col2img1 from '../images/featured-3-col/GP1-HA-Sub-Category-Freezer-7-1-D-2023(UK).jpg';
import col2img2 from '../images/featured-3-col/GP1-HA-Sub-Category-Freezer-7-2-D-2023(UK).jpg';
import col2img3 from '../images/featured-3-col/GP1-HA-Sub-Category-Freezer-7-3-D-2023(UK).jpg';
import col3img1 from '../images/featured-3-col/pc-category-02-2-1-gram.jpg';
import col3img2 from '../images/featured-3-col/pc-category-02-2-2-gram-style.jpg';
import col3img3 from '../images/featured-3-col/pc-category-02-2-3-gram-plus-view.jpg';


const LgFeatureProductStory = () => {
  return (
      <>
            <div className='ft-prod-story-container'>
              <span className='ft-prod-story'>Feature Events Story</span>
            </div>
          <div className='product-desc-spec' style={{ borderTop: "none" }}>
            <Tabs>
                    <TabList>
                    <Tab>Jatras / Festivals</Tab>
                    <Tab>Concerts</Tab>
                    <Tab>Exhibitions</Tab>
                    </TabList>

                    <TabPanel>
                        <p>
                            <div className='producty-story'>
                                  <span className="producty-story-title">Gai Jatra</span>
                                  <span className="producty-story-description">Honoring Loved Ones, Celebrating Life</span>
                                  <span className="producty-story-button">
                                      <button onClick={() => {
                    window.location.href = "/blogs";
                    }} className='learn-more-btn giverightmargin'>Learn More</button>
                                      <button onClick={() => {
                    window.location.href = "/magazine";
                    }} className='learn-more-btn giverightmargin'>See Calendar</button>
                                  </span>
                              </div>
                            <img src={ story1 } alt="LG"/>
                        </p>
                          <div className='lg-box-ft'>
                              <div className='lg-sub-box-ft'>
                                    <img src={col1img} alt="LG Nepal" />
                                    <span className='lg-sub-box-ft-subtitle'>Celebrate the Spirit of Saat Gaaun Jatra</span>
                                    <span className='lg-sub-box-ft-title'>Saat-Gaun Jatra</span>
                              </div>
                              <div className='lg-sub-box-ft'>
                                    <img src={col2img} alt="LG Nepal" />
                                    <span className='lg-sub-box-ft-subtitle'>Witness the Power of Bagh Bhairab Jatra</span>
                                    <span className='lg-sub-box-ft-title'>Bagh Bhairab Jatra</span>
                              </div>
                              <div className='lg-sub-box-ft'>
                                    <img src={col3img} alt="LG Nepal" />
                                    <span className='lg-sub-box-ft-subtitle'>Experience the vibrant Chobar Jatra festival</span>
                                    <span className='lg-sub-box-ft-title'>Chobhar Jatra</span>
                              </div>
                          </div>
                    </TabPanel>
                    <TabPanel>
                      <p>
                          <div className='producty-story'>
                                <span className="producty-story-title">Side by Side Fridge</span>
                                <span className="producty-story-description">InstaView Door-in-Door™ in Matt Black</span>
                                <span className="producty-story-button">
                                    <button className='learn-more-btn giverightmargin'>Learn More</button>
                                    <button className='learn-more-btn giverightmargin'>Buy Now</button>
                                </span>
                            </div>
                          <img src={ story2 } alt="LG"/>
                          </p>
                                        <div className='lg-box-ft'>
                                            <div className='lg-sub-box-ft'>
                                                  <img src={col2img1} alt="LG Nepal" />
                                                  <span className='lg-sub-box-ft-subtitle'>InstaView™</span>
                                                  <span className='lg-sub-box-ft-title'>Rock every occasion with us</span>
                                            </div>
                                            <div className='lg-sub-box-ft'>
                                                  <img src={col2img2} alt="LG Nepal" />
                                                  <span className='lg-sub-box-ft-subtitle'>DoorCooling+™</span>
                                                  <span className='lg-sub-box-ft-title'>Chill your fridge contents faster</span>
                                            </div>
                                            <div className='lg-sub-box-ft'>
                                                  <img src={col2img3} alt="LG Nepal" />
                                                  <span className='lg-sub-box-ft-subtitle'>UVnano™</span>
                                                  <span className='lg-sub-box-ft-title'>Built-in UV LED light keeps your water clean</span>
                                            </div>
                                        </div>
                    </TabPanel>
                    <TabPanel>
                      <p>
                          <div className='producty-story'>
                                <span className="producty-story-title">Born to Game</span>
                                <span className="producty-story-description">Gaming Monitors</span>
                                <span className="producty-story-button">
                                    <button className='learn-more-btn giverightmargin'>Learn More</button>
                                    <button className='learn-more-btn giverightmargin'>Buy Now</button>
                                </span>
                            </div>
                          <img src={story3} alt="LG" />
                          </p>
                          <div className='lg-box-ft'>
                                                          <div className='lg-sub-box-ft'>
                                                                <img src={col3img1} alt="LG Nepal" />
                                                                <span className='lg-sub-box-ft-subtitle'>Gram superslim</span>
                <span className='lg-sub-box-ft-title'>Unbelievably thin & light</span>
                <span className='lg-sub-box-ft-description'>The gram SuperSlim shows off a light body of 990g and 10.9mm thickness while keeping its high performance.</span>
                <a href="#" class="learn-more-btn" style={{ marginTop: "10px" }}>Learn More</a>
                                                          </div>
                                                          <div className='lg-sub-box-ft'>
                                                                <img src={col3img2} alt="LG Nepal" />
                                                                <span className='lg-sub-box-ft-subtitle'>Gram style</span>
                <span className='lg-sub-box-ft-title'>Beauty meets strength</span>
                <span className='lg-sub-box-ft-description'>The new design with a color-changing cover elevates your style while adding solidity to the laptop.</span>
                <a href="#" class="learn-more-btn" style={{ marginTop: "10px" }}>Learn More</a>
                                                          </div>
                                                          <div className='lg-sub-box-ft'>
                                                                <img src={col3img3} alt="LG Nepal" />
                                                                <span className='lg-sub-box-ft-subtitle'>Gram +view</span>
                <span className='lg-sub-box-ft-title'>Expand your view</span>
                <span className='lg-sub-box-ft-description'>Enjoy a broader view anywhere with +view and LG gram.</span>
                <a href="#" class="learn-more-btn" style={{ marginTop: "10px" }}>Learn More</a>
                                                          </div>
                                                      </div>
                    </TabPanel>
              </Tabs>
      </div>
      
      
      
      </>
  )
}

export default LgFeatureProductStory;
